import React, { useState } from 'react';
import styles from "./index.module.scss";
import ToolBoxTop from "../../components/registrationToolBlocks/main";
import { Alert, Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Snackbar } from "@mui/material";
import { ReactComponent as SearchNormal } from "../../assets/img/search-normal.svg"
import "react-big-calendar/lib/css/react-big-calendar.css";
import CalendarMain from "../../components/calendar/main";
import { IBranch, IDoctor, IDoctors, IDoctorsItem, ISpeciality } from "../../consts/types";
import { observer, useLocalObservable } from "mobx-react-lite";
import moment from "moment";
import CalendarEventStateKeeper from "../../store/CalendarEventStateKeeper";
// @ts-ignore
import ErrorNotification from "../../store/ErrorNotification";
import { toJS } from 'mobx';


const MainView = observer((
    {
        selectData,
        setSelectData,
        specialities,
        doctors,
        changeSpecialty,
        onSelectDoctor,
        selectedDoctors,
        searchInputsValue,
        searchInputsHandler,
        branchesCopy,
        handleChangeBranch
    }: {
        selectData: string
        setSelectData: (str: string) => void
        specialities: Array<ISpeciality>
        doctors: IDoctors,
        changeSpecialty: (id: string) => void
        onSelectDoctor: (e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>, data: IDoctorsItem) => void
        selectedDoctors: IDoctors
        searchInputsValue: { specialities: string, doctors: string }
        searchInputsHandler: (type: string, value: string) => void,
        branchesCopy: Array<IBranch>,
        handleChangeBranch: (str: string) => void
    }
) => {
    const { openNotification, changeVisibilityNotification } = useLocalObservable(() => ErrorNotification.instance);

    const changeButtons: Array<{ text: string, type: string }> = [
        {
            text: "месяц",
            type: "month"
        },
        {
            text: "неделя",
            type: "week"
        },
        {
            text: "день",
            type: "day"
        },

    ];
    const calendarEventsStateKeeper = useLocalObservable(() => CalendarEventStateKeeper.instance);
    const { changeViewFunctions, calendarView, setCalendarView } = calendarEventsStateKeeper;

    const [active, setActive] = useState<string>("month");
    // const [calendarView, setCalendarView] = useState<number>(0);

    const changeView = (type: string) => {
        setActive(type);
        console.log(type, "changeView")
        changeViewFunctions.forEach(item => item(type))
    }
    const changeCalendar = (type: number) => {
        setCalendarView(type);
    }

    const convertDate = () => {
        const momentRu = moment().locale("ru")
        if (active === "month") {
            return momentRu.format("MMMM YYYY")
        } else if (active === "week") {
            let startWeek = momentRu.startOf("week").format("DD MMMM YYYY");
            let endWeek = momentRu.endOf("week").format("DD MMMM YYYY");
            return `${startWeek} - ${endWeek}`;
        } else if (active === "day") {
            return momentRu.format("dddd | DD MMMM");
        }
    }
    // console.log({ doctors });

    return (
        <>
            <div className={styles.main_block}>
                <div className={styles.top}>
                    <ToolBoxTop />
                </div>
                <div className={styles.main_content}>
                    <div className={styles.table}>
                        <Box sx={{ mb: "10px" }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Филиал</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectData}
                                    label="Филиал"
                                    onChange={(e) => handleChangeBranch(e.target.value)}
                                >
                                    {
                                        branchesCopy.map((item, index) => (
                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Grid container mb="10px">
                            <Grid item xl={6}>
                                <FormControl sx={{ width: "301px", height: "100%", marginRight: "20px" }}
                                    variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Поиск специалности</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        value={searchInputsValue.specialities}
                                        onChange={(e) => searchInputsHandler("specialities", e.target.value)}
                                        endAdornment={
                                            <SearchNormal position="end" />
                                        }
                                        label="Поиск специалности"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xl={6} sx={{ textAlign: "right" }}>
                                <FormControl sx={{ width: "301px", height: "100%" }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Поиск врачей</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        value={searchInputsValue.doctors}
                                        onChange={(e) => searchInputsHandler("doctors", e.target.value)}
                                        endAdornment={
                                            <SearchNormal position="end" />
                                        }
                                        label="Поиск врачей"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>


                        <div className={styles.table_block}>
                            <div className={`${styles.table_medical} ${styles.custom_scrollbar}`}>
                                <div onClick={() => changeSpecialty("all")} className={styles.table_item}>Все</div>
                                {
                                    specialities.map((item, i) => (
                                        <div
                                            onClick={() => changeSpecialty(item.id)}
                                            // style={{ backgroundColor: item.color }}
                                            className={styles.table_item}
                                            key={item.id}
                                        >
                                            {item.specialty_name ?? item.name}
                                        </div>
                                    ))
                                }
                            </div>

                            <div className={`${styles.table_doctors} ${styles.custom_scrollbar}`}>
                                {
                                    toJS(doctors).map((doctor: IDoctorsItem) => {
                                        // console.log({ doctor })
                                        return (
                                            doctor && (
                                                <div
                                                    onClick={(e) => onSelectDoctor(e, doctor)}
                                                    key={doctor.id}
                                                    className={`doctors_table_row ${styles.row} ${selectedDoctors.map((doctor) => doctor.id).includes(doctor.id) ? styles.selected : ""}`}
                                                // style={{backgroundColor: item.color}}
                                                >
                                                    <div className={styles.cell}>
                                                        <label className={styles.checkbox_block}>
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedDoctors.map((doctor) => doctor.id).includes(doctor.id)}
                                                                onChange={(e) => onSelectDoctor(e, doctor)}
                                                            />
                                                            <div className={styles.box}></div>
                                                        </label>
                                                    </div>
                                                    <div className={styles.cell}>
                                                        {doctor.doctor.f_name || doctor.doctor.username}
                                                    </div>
                                                    <div className={styles.cell}>
                                                        {doctor.specialty?.map(item => item.name).join()}
                                                    </div>
                                                    <div className={styles.cell}>
                                                        {doctor.doctor.phone_number}
                                                    </div>
                                                    <div className={styles.cell}>
                                                        <div
                                                            className={`${styles.status_block} ${doctor?.doctor.active ? "" : styles.not_active}`}></div>
                                                    </div>
                                                </div>
                                            )
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.calendar}>
                        {
                            selectedDoctors?.length > 0 &&
                            <div className={styles.toolbar}>
                                {/* <div className={styles.button_now}>Сегодня</div> */}
                                <div className={`month_buttons_block ${styles.buttons_change}`}>
                                    {
                                        selectedDoctors.map((doctor, index) => (
                                            <div
                                                key={index}
                                                className={`month_buttons ${styles.button_item}  ${calendarView == index ? `month_button_active ${styles.active}` : ''} `}
                                                onClick={() => changeCalendar(index)}
                                            >{doctor.doctor.f_name ?? doctor.doctor.username}
                                            </div>
                                        ))
                                    }
                                </div>
                                <p className={styles.now_day}> {convertDate()}</p>

                                <div className={`month_buttons_block ${styles.buttons_change}`}>
                                    {changeButtons.map((item, i) => (
                                        <div
                                            key={item.type}
                                            onClick={(e) => changeView(item.type)}
                                            className={`month_buttons ${styles.button_item} ${item.type === active ? `month_button_active ${styles.active}` : ""}`}
                                            data-type={item.type}
                                        >
                                            {item.text}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                        <div className={styles.calendar_main}>
                            <CalendarMain />
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar
                open={openNotification}
                autoHideDuration={3000}
                onClose={() => changeVisibilityNotification(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={() => changeVisibilityNotification(false)}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    Пожалуйста выберете сначала доктора
                </Alert>
            </Snackbar>
        </>
    );
});

export default MainView;