import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import classes from "./patientsDirectory.module.scss";
import { Control, Controller, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { type IPatientDirectory } from "../../consts/patientsDirectory";
import { IPatientSearchParams } from "../../consts/types";
import moment from "moment";

interface PatientsFilterProps {
    item: IPatientDirectory,
    index: number,
    control: Control<IPatientSearchParams, any>,
    handleSearchPatient: (event: React.ChangeEvent<HTMLInputElement>) => void,
    watch: UseFormWatch<IPatientSearchParams>
    setValue: UseFormSetValue<IPatientSearchParams>
    onBlur: () => void
}

export const PatientsFilterFields = (props: PatientsFilterProps) => {
    const { item, index, control, handleSearchPatient, watch, setValue, onBlur } = props

    if (item.type === "select") {
        return (
            <FormControl className={classes.navbarItem} fullWidth key={index}>
                <InputLabel id="demo-select-small">{item.label}</InputLabel>
                <Select
                    labelId={"demo-select-small" + index}
                    id={"demo-select-small" + index}
                >
                    {item.child?.([])?.map((child, i) => {
                        return (
                            <MenuItem key={i} value={child.value}>
                                {child.name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        )
    }

    if (item.type === "text" || item.type === "number") {
        return (
            item.name ? (
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field }) => (
                        <FormControl className={classes.navbarItem} fullWidth key={index}>
                            <TextField
                                id="outlined-search"
                                label={item.label}
                                type={item.type}
                                {...field}
                                onBlur={() => {
                                    field.onBlur()
                                    onBlur();
                                }}
                            />
                        </FormControl>
                    )}
                />
            ) : (
                <FormControl className={classes.navbarItem} fullWidth key={index}>
                    <TextField
                        id="outlined-search"
                        label={item.label}
                        type={item.type}
                        onChange={handleSearchPatient}
                    />
                </FormControl>
            ))
    }

    if (item.type === "date") {
        return (item.name ? (
            <FormControl className={classes.navbarItem} fullWidth key={index}>
                <DatePicker
                    value={watch('date_of_birth')}
                    onChange={(value) => { setValue('date_of_birth', `${value ? moment(value).format('MM/DD/YYYY') : ''}`) }}
                    label={item.label}
                    inputFormat="MM/DD/YYYY"
                    renderInput={(params) => <TextField
                        {...params}
                        error={false}
                        onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
                            params?.onBlur?.(e)
                            onBlur()
                        }}
                    />}
                />
            </FormControl>
        ) : (
            <FormControl className={classes.navbarItem} fullWidth key={index}>
                <MobileDatePicker
                    onChange={() => { }}
                    value={new Date(Date.now())}
                    label={item.label}
                    inputFormat="MM/DD/YYYY"
                    renderInput={(params) => <TextField {...params} />}
                />
            </FormControl>
        ))
    }

    return null

}