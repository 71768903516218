import { makeAutoObservable, observable, runInAction } from "mobx";
import { DoctorApiStub } from "../repositories";
import { IDoctor, IDoctors, IInformationSource, IReferring } from "../consts/types";

class DoctorStateKeeper {

    static _instance: DoctorStateKeeper;
    private readonly doctorApiStub: DoctorApiStub;

    doctors: IDoctors = [];
    doctorsCopy: IDoctors = [];
    selectedDoctor: IDoctor | null = null;
    selectedDoctors: IDoctors = [];

    static get instance() {
        if (!DoctorStateKeeper._instance) {
            DoctorStateKeeper._instance = new DoctorStateKeeper();
        }
        return DoctorStateKeeper._instance;
    }

    constructor(
        doctorApiStub: DoctorApiStub = DoctorApiStub.instance
    ) {
        this.doctorApiStub = doctorApiStub;
        makeAutoObservable(this, {
            findAllReferrings: observable,
            findAllDoctors: observable,
            findAllInformationSource: observable,
        }, { autoBind: true });
    }

    findAllDoctors = async (): Promise<IDoctors> => {
        const doctors = await this.doctorApiStub.findAllDoctors();
        runInAction(() => {
            this.doctors = doctors;
            this.doctorsCopy = doctors;
        });
        return doctors;
    }

    findAllReferrings = async (): Promise<IReferring[]> => {
        const referrings = await this.doctorApiStub.findAllReferrings();

        return referrings;
    }

    findAllInformationSource = async (): Promise<IInformationSource[]> => {
        const infoSources = await this.doctorApiStub.findAllInformationSource();

        return infoSources;
    }

    setSelectedDoctor = (doctor: IDoctor) => {
        this.selectedDoctor = doctor;
    }

    setSelectedDoctors = (doctors: IDoctors) => {
        this.selectedDoctors = doctors;
    }

    setDoctorsCopy = (doctors: IDoctors) => {
        this.doctorsCopy = doctors;
    }

    searchDoctor = (str: string) => {
        if (str === "") {
            this.doctorsCopy = this.doctors
            return false
        }
        this.doctorsCopy = this.doctors.filter(item => {
            return (
                item.doctor.f_name?.toLowerCase().includes(str?.toLowerCase())
                || item.doctor.specialty?.[0]?.specialty_name?.toLowerCase().includes(str?.toLowerCase())
                || item.doctor.email?.toLowerCase().includes(str?.toLowerCase())
            )
        })
    }
}

export default DoctorStateKeeper;

