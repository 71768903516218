import { makeAutoObservable, runInAction } from "mobx";
import { CalendarEventApiStub } from "../repositories";
import { IEvent } from "../consts/types";

class CalendarEventStateKeeper {
  static _instance: CalendarEventStateKeeper;
  calendarEventApiStub = new CalendarEventApiStub();


  static get instance() {
    if (!CalendarEventStateKeeper._instance) {
      CalendarEventStateKeeper._instance = new CalendarEventStateKeeper();
    }
    return CalendarEventStateKeeper._instance;
  }

  events: IEvent[] = [];

  eventsCopy: IEvent[] = [];

  changeViewFunctions: Array<any> = [];

  calendarView: number = 0;

  constructor(
    calendarEventApiStub: CalendarEventApiStub = CalendarEventApiStub.instance
  ) {
    this.calendarEventApiStub = calendarEventApiStub;
    makeAutoObservable(this);
  }

  addEvent = (data: IEvent) => {
    this.events.push(data);
    this.eventsCopy = this.events;
  }

  setCalendarView = (key: number) => {
    this.calendarView = key;
  }

  filterEventByDoctorId = (id: string) => {
    console.log(
      "appointment id",
      id,
      this.events.map((i) => i.doctorId)
    );
    return this.events.filter((item) => item.doctorId === id);
  }

  filterEventByIds = (ids: string[]) => {
    this.eventsCopy = this.events.filter((item) =>
      ids.includes(item.doctorId || "")
    );
  }

  addViewAction = (func) => {
    this.changeViewFunctions.push(func);
  }

  findAllAppointments = async (): Promise<IEvent[]> => {
    const appointments = await this.calendarEventApiStub.findAllAppointments();
    runInAction(() => {
      if (!Number.isNaN(this.events?.length) && !Number.isNaN(appointments?.length)) {
        if (this.events.length < appointments.length) {
          this.events = appointments.map((appointment) => {
            return {
              id: appointment.id,
              doctorId: String(appointment.doctor),
              title: appointment.name,
              start: new Date(appointment.start_time),
              end: new Date(appointment.end_time),
            };
          });
        }
      }
      this.eventsCopy = this.events;
    });

    return this.events;
  }
}

export default CalendarEventStateKeeper;