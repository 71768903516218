import { Button, SelectChangeEvent } from "@mui/material";
import { observer, useLocalObservable } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import BpCheckbox from "../../components/BpCheckbox";
import {
  PatientsDirectoryActions,
  PatientsDirectoryNav,
} from "../../consts/patientsDirectory";
import { IPatient, IPatientSearchParams } from "../../consts/types";
import useDebounce from "../../hooks/useDebounce";
import classes from "./patientsDirectory.module.scss";
import { PatientApiStub } from "../../repositories";
import { useForm } from "react-hook-form";
import { PatientsFilterFields } from "./filterFields";
import { toJS } from "mobx";

const PatientsDirectory = observer(
  ({
    patients,
    filteredPatients,
    filterPatients
  }: {
    patients: Array<IPatient>,
    filteredPatients: Array<IPatient>
    filterPatients: (params: IPatientSearchParams) => Promise<IPatient[]>
  }) => {
    const [searchedValue, setSearchedValue] = React.useState<string>("");
    const [age, setAge] = React.useState(["", ""]);
    const [active, setactive] = React.useState<object[]>([]);
    const [selected, setSelected] = React.useState<string>("0");
    const navigate = useNavigate();
    const debouncedValue = useDebounce<string>(searchedValue, 500);

    const defaultValues = {
      l_name: '',
      f_name: '',
      mid_name: '',
      INN: '',
      doc_number: '',
      date_of_birth: '',
      mobile_phone_number: '',
      id: '',
      last_visit_at: '',
      search: '',
      ordering: '',
    }
    const patientStateKeeper = useLocalObservable(
      () => PatientApiStub.instance
    );
    const { control, watch, setValue } = useForm<IPatientSearchParams>({ defaultValues })
    const { searchPatients } = patientStateKeeper;

    React.useEffect(() => {
      if (searchedValue?.length > 0) {
        searchPatients(searchedValue).then();
      }
    }, [debouncedValue]);

    const onBlur = () => {
      const values = Object.entries(watch()).reduce((acc, [k, v]) => v ? { ...acc, [k]: v } : acc, {})
      filterPatients(values)
    }

    const handleChange = (event: SelectChangeEvent) => {
      setAge([...age, event.target.value]);
    };

    const handleSelectPatient = (event: React.MouseEvent<HTMLElement>) => {
      setSelected(event.currentTarget.dataset.id || "");
    };

    const handleSeachPatient = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchedValue(event.target.value);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      const type = event.currentTarget.dataset.type;
      switch (type) {
        case "add":
          navigate("/patientsDirectory/create");
          break;
        case "edit":
          if (selected !== "0") {
            navigate(`/patientsDirectory/edit/${selected}`);
          }
          break;
        case "remove":
          alert(JSON.stringify(selected));
          break;

        default:
          break;
      }
    };

    const handlecheckboxchange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, name } = e.currentTarget;
      if (checked) {
        setactive({ ...active, [name]: checked });
      } else {
        delete active[name];
        setactive(active);
      }
    };

    return (
      <div className={classes.main}>
        <nav className={classes.actionButtons}>
          {PatientsDirectoryActions.map((button, index) => (
            <Button
              color={button.text === "Удалить" ? "error" : "primary"}
              size="large"
              className={classes.actionButton}
              variant="contained"
              key={index}
              onClick={handleClick}
              data-type={button.dataset}
              disabled={
                button.text === "Редактировать" && selected === "0"
                  ? true
                  : false
              }
            >
              {button.icon}
              {button.text}
            </Button>
          ))}
        </nav>

        <nav className={classes.navbar}>
          {PatientsDirectoryNav.map((item, index) => {
            return (
              <PatientsFilterFields
                control={control}
                handleSearchPatient={handleSeachPatient}
                index={index}
                item={item}
                key={item.label}
                watch={watch}
                setValue={setValue}
                onBlur={onBlur}
              />
            )
          })}
        </nav>
        {filteredPatients?.length > 0 && (
          <div className={classes.patientsList}>
            <table className={classes.table}>
              <thead className={classes.tableHead}>
                {[
                  "",
                  "Фамилия",
                  "Имя",
                  "Отчество",
                  "Пол",
                  "Адрес",
                  "Моб.телефон",
                  "Дом.телефон",
                  "Дата регис трации",
                  "Регистратор",
                  "Активный",
                  "Дата последного посещение",
                ].map((item) => (
                  <th key={item}>{item}</th>
                ))}
              </thead>
              {toJS(filteredPatients).map((item) => {
                // console.log({ item });
                return (
                  <tr
                    data-id={item.id}
                    onClick={handleSelectPatient}
                    className={
                      item.id === parseInt(selected) ? classes.selectedDoctor : ""
                    }
                  >
                    <td></td>
                    <td>{item.l_name}</td>
                    <td>{item.f_name}</td>
                    <td>{item.mid_name}</td>
                    <td>{item.sex}</td>
                    <td>{item.address}</td>
                    <td>{item.mobile_phone_number}</td>
                    <td>{item.homPhoneNumber}</td>
                    <td>{new Date(item.created_at).toLocaleDateString()}</td>
                    <td>{item.email}</td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      <BpCheckbox
                        id={item.id}
                        handlecheckboxchange={handlecheckboxchange}
                        defaultChecked={false}
                      />
                    </td>
                    <td>{item.last_visit_at}</td>
                  </tr>
                )
              })}
            </table>
          </div>
        )}
      </div >
    );
  }
);

export default PatientsDirectory;
