import React from 'react';
import Calendar from "./calendar";

const ToolBoxTop = () => {
    return (
        <>
            <Calendar/>
        </>
    );
};

export default ToolBoxTop;