import { ApiClient } from "../utils";

import { IBranch } from "../consts/types";
import { runInAction } from "mobx";

export default class BranchesApiStub {
  /* Gen by NARA Studio */
  static _instance: BranchesApiStub;

  private readonly client = new ApiClient("organizations/branches");

  static get instance() {
    /* Gen by NARA Studio */
    if (!BranchesApiStub._instance) {
      BranchesApiStub._instance = new BranchesApiStub();
    }
    return BranchesApiStub._instance;
  }

  branchesCopy: IBranch[] = [];

  async findAllBranches(): Promise<IBranch[]> {
    /* Gen by NARA Studio */
    return this.client.getData<IBranch>("/").then(branches => {
      runInAction(() => {
        this.branchesCopy = branches;
      });
      return branches
    });
  }
}
