import React from "react";
import { ReactComponent as CalendarEdit } from "../assets/img/header_icons/calendar-edit.svg";
import { ReactComponent as CloseCircle } from "../assets/img/close-circle.svg";
import { IPatientSearchParams } from "./types";

type IChild = Array<{
    name: string
    value: string
}>
export interface IPatientDirectory {
    type: string;
    name?: keyof IPatientSearchParams;
    label: string;
    child?: (arr: IChild) => IChild
}
interface DirectoryActions {
    icon: typeof CalendarEdit;
    text: string;
    dataset: string
}

export const PatientsDirectoryNav: Array<IPatientDirectory> = [
    {
        type: "text",
        label: "ФИО",
        name: 'f_name'
    },
    {
        type: "number",
        label: "ИНН",
        name: 'INN'
    },
    {
        type: "number",
        label: "№ медкарты",
    },
    {
        type: "number",
        label: "№ документа",
    },
    {
        type: "number",
        label: "Моб. телефон",
    },
    {
        type: "email",
        label: "Эл. почта",
    },
    {
        type: "date",
        label: "Дата рождения",
        name: "date_of_birth"
    },
    {
        type: "date",
        label: "Дата регистрации",
    },
    {
        type: "select",
        label: "Регистратор",
        child: (arr) => arr
    },
];
export const PatientsDirectoryActions: Array<DirectoryActions> = [
    {
        icon: <CalendarEdit />,
        text: "Добавить",
        dataset: 'add'
    },
    {
        icon: <CalendarEdit />,
        text: "Редактировать",
        dataset: 'edit'
    },
    {
        icon: <CloseCircle />,
        text: "Удалить",
        dataset: 'remove'
    },
];

export const PatientsDirectoryEditActions: Array<DirectoryActions> = [
    {
        icon: <CalendarEdit />,
        text: "Добавить",
        dataset: 'add'
    },

    {
        icon: <CloseCircle />,
        text: "Отмена",
        dataset: 'remove'
    },
];
