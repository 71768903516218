import { makeAutoObservable, runInAction } from "mobx";
import { PatientApiStub } from "../repositories";
import { IMergePatient, IPatient, IPatientSearchParams } from "../consts/types";

class PatientStateKeeper {

    static _instance: PatientStateKeeper;
    private readonly patientApiStub: PatientApiStub;

    patients: IPatient[] = [];
    filteredPatients: IPatient[] = [];
    selectedPatient: IPatient | null = null;

    static get instance() {
        if (!PatientStateKeeper._instance) {
            PatientStateKeeper._instance = new PatientStateKeeper();
        }
        return PatientStateKeeper._instance;
    }

    constructor(
        patientApiStub: PatientApiStub = PatientApiStub.instance
    ) {
        this.patientApiStub = patientApiStub;
        makeAutoObservable(this, {}, { autoBind: true });
    }

    async findAllPatients(): Promise<IPatient[]> {
        const patients = await this.patientApiStub.findAllPatients();
        runInAction(() => {
            this.patients = patients;
        });
        return patients;
    }

    async filterPatients(params: IPatientSearchParams): Promise<IPatient[]> {
        const patients = await this.patientApiStub.filterPatients(params);
        runInAction(() => {
            this.filteredPatients = patients;
        });
        return patients;
    }

    mergePatients(props: { patients: IMergePatient[] }) {
        const patients = this.patientApiStub.findAllPatients();
    }

    setSelectedPatient(patient: IPatient | null) {
        this.selectedPatient = patient;
    }
}

export default PatientStateKeeper;

