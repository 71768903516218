import { makeAutoObservable, observable, runInAction } from "mobx";
import { BranchesApiStub } from "../repositories";
import { IBranch } from "../consts/types";

class BranchStateKeeper {

    static _instance: BranchStateKeeper;
    private readonly branchApiStub: BranchesApiStub;

    branches: IBranch[] = [];
    branchesCopy: IBranch[] = [];

    static get instance() {
        if (!BranchStateKeeper._instance) {
            BranchStateKeeper._instance = new BranchStateKeeper();
        }
        return BranchStateKeeper._instance;
    }

    constructor(
        branchApiStub: BranchesApiStub = BranchesApiStub.instance
    ) {
        this.branchApiStub = branchApiStub;
        makeAutoObservable(this, { findAllBranches: observable }, { autoBind: true });
    }

    findAllBranches = async (): Promise<IBranch[]> => {
        const branches = await this.branchApiStub.findAllBranches();
        runInAction(() => {
            this.branches = branches;
            this.branchesCopy = branches;
        });
        return branches;
    }

}

export default BranchStateKeeper;

